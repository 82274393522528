import yup from './yupUtil';
import {
	validateToBeLessThanOrEqualToFieldValue,
	validateToBeExactlyEqualToFieldValue,
	validateZeroEnrollmentReason,
	validateVaccineRangeToFieldValue,
	validateForIntValues,
	validateEnrolledField
} from './commonFormValidation';
import { SRS_ERRORS_CONSTANTS } from 'src/constants';

// schema declaration..
export const childCareStepFormSchema = yup.object().shape({
	childMissingCount:
		validateVaccineRangeToFieldValue('sumMissingVaccines', 1, false) ||
		validateVaccineRangeToFieldValue('sumMissingVaccines', 6, false),
	sumMissingVaccines: yup.number(),
	sumChildEnteredCount: validateToBeExactlyEqualToFieldValue('enrolledStudentCount'),
	enrolledStudentCount: validateEnrolledField(),
	facilityType: yup.string().required('Facility type is required'),
	zeroEnrollmentReason: validateZeroEnrollmentReason('enrolledStudentCount'),
	reqVaccineDosesCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	permMedicalExamCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherIEPServicesCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	condMissingCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	tempMedicalExempCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	overDueCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	polioCount: validateToBeLessThanOrEqualToFieldValue('childMissingCount'),
	mmrCount: validateToBeLessThanOrEqualToFieldValue('childMissingCount'),
	hibCount: validateToBeLessThanOrEqualToFieldValue('childMissingCount'),
	hepBCount: validateToBeLessThanOrEqualToFieldValue('childMissingCount'),
	dtapCount: validateToBeLessThanOrEqualToFieldValue('childMissingCount'),
	varicellaCount: validateToBeLessThanOrEqualToFieldValue('childMissingCount'),
	metadata: yup.object().shape({
		submittedDate: yup.string(),
		revisedDate: yup.string(),
	}),
});
//schema declaration for entered values to be integer
export const childCareStepFormIntValues = yup.object().shape({
	enrolledStudentCount: validateForIntValues(),
	reqVaccineDosesCount: validateForIntValues(),
	permMedicalExamCount: validateForIntValues(),
	otherIEPServicesCount: validateForIntValues(),
	condMissingCount: validateForIntValues(),
	tempMedicalExempCount: validateForIntValues(),
	overDueCount: validateForIntValues(),
	polioCount: validateForIntValues(),
	mmrCount: validateForIntValues(),
	hibCount: validateForIntValues(),
	hepBCount: validateForIntValues(),
	dtapCount: validateForIntValues(),
	varicellaCount: validateForIntValues(),
});

// form initialization..
export const childCareStepFormModel = {
	childMissingCount: 0,
	sumChildEnteredCount: 0,
	sumMissingVaccines: 0,
	enrolledStudentCount: '',
	zeroEnrollmentReason: '',
	facilityType: '',
	reqVaccineDosesCount: 0,
	permMedicalExamCount: 0,
	otherIEPServicesCount: 0,
	polioCount: 0,
	dtapCount: 0,
	condMissingCount: 0,
	mmrCount: 0,
	tempMedicalExempCount: 0,
	hibCount: 0,
	hepBCount: 0,
	overDueCount: 0,
	varicellaCount: 0,
	metadata: {
		submittedDate: "",
		revisedDate: ""
	}
};
