import { createSlice } from '@reduxjs/toolkit';

import { getCurrentStepInitialValues } from '../../../pages/dashboard/form-model';
import { VALID_COHORTS, SRS_APP_CONSTANTS, STEP_STATUSES } from '../../../constants';
import { ca } from 'date-fns/locale';

const getFormattedGradeName = (gradeName, isSecondary = false) => {
	let result = '';

	switch (gradeName) {
		case SRS_APP_CONSTANTS.information.type:
			result = isSecondary
				? SRS_APP_CONSTANTS.information.secondaryDisplayName
				: SRS_APP_CONSTANTS.information.displayName;
			break;
		case SRS_APP_CONSTANTS.childCare.type:
			result = isSecondary ? SRS_APP_CONSTANTS.childCare.secondaryDisplayName : SRS_APP_CONSTANTS.childCare.displayName;
			break;
		case SRS_APP_CONSTANTS.kindergarten.type:
			result = isSecondary
				? SRS_APP_CONSTANTS.kindergarten.secondaryDisplayName
				: SRS_APP_CONSTANTS.kindergarten.displayName;
			break;
		case SRS_APP_CONSTANTS.firstGrade.type:
			result = isSecondary
				? SRS_APP_CONSTANTS.firstGrade.secondaryDisplayName
				: SRS_APP_CONSTANTS.firstGrade.displayName;
			break;
		case SRS_APP_CONSTANTS.seventhGrade.type:
			result = isSecondary
				? SRS_APP_CONSTANTS.seventhGrade.secondaryDisplayName
				: SRS_APP_CONSTANTS.seventhGrade.displayName;
			break;
		case SRS_APP_CONSTANTS.eighthGrade.type:
			result = isSecondary
				? SRS_APP_CONSTANTS.eighthGrade.secondaryDisplayName
				: SRS_APP_CONSTANTS.eighthGrade.displayName;
			break;
		default:
			break;
	}

	return result;
};

const getStepStatusFromFormData = (formData: any): string => {
	return formData?.status || STEP_STATUSES.PRISTINE;
};

export const slice = createSlice({
	name: 'schoolInfo',
	initialState: {
		currentFormData: null,
		initialFormData: null,
		steps: [],
		isEveryStepCompleted: false,
		activeStepIndex: 0,
		isFormDirty: false,
		sortedApplicableCohortsList: [],
		designatedContacts: [],
		status: 'Not Started',
		error: null,
	},
	reducers: {
		setSchoolInfoInState: (state, action) => {
			const apiResponse: {
				schoolInformationCohort: any[];
				schoolInformation: any[];
				schoolDesignatedContact: any[];
				selectedSchool: any;
				tempSchoolData: any;
				openViewReport: boolean;
			} = action.payload;
			if (!apiResponse) {
				state = {
					currentFormData: null,
					initialFormData: null,
					steps: [],
					isEveryStepCompleted: false,
					activeStepIndex: 0,
					isFormDirty: false,
					sortedApplicableCohortsList: [],
					designatedContacts: [],
					status: 'Not Started',
					error: null,
				};
			} else {
				var response = {
					schInformation: apiResponse.schoolInformation[0],
					schoolDesignatedContacts: apiResponse.schoolDesignatedContact,
					cohorts: apiResponse.schoolInformationCohort.map((x) => x.cohort),
					school: apiResponse.selectedSchool,
				};
				var obj = {
					designatedContacts: apiResponse.schoolDesignatedContact,
					id: apiResponse.schoolInformation[0].id,
					status: apiResponse.schoolInformation[0].status,
					schoolId: apiResponse.schoolInformation[0].schoolId,
					applicableCohortGroupIds: apiResponse.schoolInformation[0].cohortGroupIds,
					isReadOnly: apiResponse.schoolInformation[0].isReadOnly,
					additionalEmail: apiResponse.schoolInformation[0].additionalEmail,
					memo: apiResponse.schoolInformation[0].memo,
				};
				response[SRS_APP_CONSTANTS.information.type] = JSON.stringify(obj);

				apiResponse.schoolInformationCohort.forEach((e) => {
					var obj = JSON.parse(JSON.stringify(e));
					obj.schoolId = apiResponse.schoolInformation[0].schoolId;
					switch (e.cohort) {
						case SRS_APP_CONSTANTS.kindergarten.shortCode:
							obj.studentMissingCount = Number(e.permMedicalExamCount || 0) +
								Number(e.otherIEPServicesCount || 0) +
								Number(e.otherIndependentStudyCount || 0) +
								Number(e.otherHomeBasedPrivateSchoolCount || 0) +
								Number(e.condMissingCount || 0) +
								Number(e.tempMedicalExempCount || 0) +
								Number(e.overDueCount || 0);

							obj.sumStudentsEnteredCount = Number(e.reqVaccineDosesCount || 0) + + obj.studentMissingCount;

							obj.sumMissingVaccines = Number(e.polioCount || 0) +
								Number(e.mmrCount || 0) +
								Number(e.hepBCount || 0) +
								Number(e.varicellaCount || 0) +
								Number(e.dtapCount || 0);
							break;
						case SRS_APP_CONSTANTS.childCare.shortCode:
							obj.childMissingCount = Number(e.permMedicalExamCount || 0) +
								Number(e.otherIEPServicesCount || 0) +
								Number(e.condMissingCount || 0) +
								Number(e.tempMedicalExempCount || 0) +
								Number(e.overDueCount || 0);

							obj.sumChildEnteredCount = Number(e.reqVaccineDosesCount || 0) + +obj.childMissingCount;

							obj.sumMissingVaccines = Number(e.polioCount || 0) +
								Number(e.mmrCount || 0) +
								Number(e.hibCount || 0) +
								Number(e.hepBCount || 0) +
								Number(e.varicellaCount || 0) +
								Number(e.dtapCount || 0);
							break;
						case SRS_APP_CONSTANTS.seventhGrade.shortCode:
							obj.totalVaricelleCount = Number(e.reqMetVaricelleCount || 0) +
								Number(e.permMedicalVaricelleCount || 0) +
								Number(e.iepVaricelleCount || 0) +
								Number(e.indepStudyVaricelleCount || 0) +
								Number(e.homeBasedVaricelleCount || 0) +
								Number(e.condMissingVaricelleCount || 0) +
								Number(e.tempMedicalExempVaricelleCount || 0) +
								Number(e.reqNotMetVaricelleCount || 0);

							obj.totalTdapCount = Number(e.reqMetTdapCount || 0) +
								Number(e.permMedicalTdapCount || 0) +
								Number(e.iepTdapCount || 0) +
								Number(e.indepStudyTdapCount || 0) +
								Number(e.homeBasedTdapCount || 0) +
								Number(e.tempMedicalExempTdapCount || 0) +
								Number(e.reqNotMetTdapCount || 0);
							break;
						case SRS_APP_CONSTANTS.eighthGrade.shortCode:
							obj.totalVaricelleCount = Number(e.reqMetVaricelleCount || 0) +
								Number(e.permMedicalVaricelleCount || 0) +
								Number(e.iepVaricelleCount || 0) +
								Number(e.indepStudyVaricelleCount || 0) +
								Number(e.homeBasedVaricelleCount || 0) +
								Number(e.condMissingVaricelleCount || 0) +
								Number(e.tempMedicalExempVaricelleCount || 0) +
								Number(e.reqNotMetVaricelleCount || 0);

							obj.totalTdapCount = Number(e.reqMetTdapCount || 0) +
								Number(e.permMedicalTdapCount || 0) +
								Number(e.iepTdapCount || 0) +
								Number(e.indepStudyTdapCount || 0) +
								Number(e.homeBasedTdapCount || 0) +
								Number(e.tempMedicalExempTdapCount || 0) +
								Number(e.reqNotMetTdapCount || 0);
							break;
						case SRS_APP_CONSTANTS.firstGrade.shortCode:

							obj.studentMissingCount = Number(e.permMedicalExamCount || 0) +
								Number(e.otherIEPServicesCount || 0) +
								Number(e.otherIndependentStudyCount || 0) +
								Number(e.otherHomeBasedPrivateSchoolCount || 0) +
								Number(e.condMissingCount || 0) +
								Number(e.tempMedicalExempCount || 0) +
								Number(e.overDueCount || 0);

							obj.sumStudentsEnteredCount = Number(e.reqVaccineDosesCount || 0) + obj.studentMissingCount;
							obj.sumMissingVaccines = Number(e.polioCount || 0) +
								Number(e.mmrCount || 0) +
								Number(e.hepBCount || 0) +
								Number(e.varicellaCount || 0) +
								Number(e.dtapCount || 0);
							break;
					}
					response[VALID_COHORTS[e.cohort].type] = JSON.stringify(obj);
				});
				const assignedCohortsList: string[] = response.cohorts;

				if (!assignedCohortsList.length) {
					throw new Error('Invalid school data. No cohorts assigned.');
				}
				// sort the list in specific required order..
				const SORTED_COHORT_LIST = [
					SRS_APP_CONSTANTS.childCare.shortCode,
					SRS_APP_CONSTANTS.kindergarten.shortCode,
					SRS_APP_CONSTANTS.seventhGrade.shortCode,
				];

				const sortedCohortList = SORTED_COHORT_LIST.reduce((result, x) => {
					if (assignedCohortsList.includes(x)) {
						result.push(x);
					}
					return result;
				}, []);

				// allow list
				const stepsNameList = sortedCohortList.map((cohortShortCode: string) => {
					return VALID_COHORTS[cohortShortCode].type;
				});

				// insert 'Information' step as first item manually..
				stepsNameList.splice(0, 0, SRS_APP_CONSTANTS.information.type);

				const filteredSteps = Object.keys(response)
					.filter((key) => stepsNameList.includes(key))
					.reduce((obj, key) => {
						try {
							const stepInitialFormData = getCurrentStepInitialValues(key);
							// RM_TODO: This is hacky & very uncommon..
							const keyValue =
								response[key] && response[key] !== ''
									? response[key]
									: JSON.stringify({ status: `${STEP_STATUSES.PRISTINE}` });
							// JSON parsing on the API response..
							obj[key] = {
								...stepInitialFormData[key],
								...JSON.parse(keyValue),
							};
						} catch (err) {
							console.error(err);
						}

						return obj;
					}, {});

				const formData = {
					...response,
					...filteredSteps,
				};

				// preparing steps
				const steps = [];
				stepsNameList.forEach((stepName) => {
					const gradeDisplayName = getFormattedGradeName(stepName, false);
					const gradeSecondaryDisplayName = getFormattedGradeName(stepName, true);
					steps.push({
						label: gradeDisplayName,
						secondaryLabel: gradeSecondaryDisplayName,
						type: stepName,
						status: getStepStatusFromFormData(formData[stepName]),
					});
				});

				// special-case for viewPrintStep
				const isEveryStepCompleted = steps.every((step) => step.status === STEP_STATUSES.COMPLETED);
				const status = steps.every(
					(step) =>
						step.status === null || step.status === '' || step.status === undefined || step.status === 'Not Started'
				)
					? 'Not Started'
					: isEveryStepCompleted
						? 'Completed'
						: 'In Progress';
				const viewPrintStepStatus = isEveryStepCompleted ? STEP_STATUSES.COMPLETED : STEP_STATUSES.PRISTINE;

				// insert 'View & Print' step as last item manually..
				steps[steps.length] = {
					label: SRS_APP_CONSTANTS.viewPrintReport.displayName,
					secondaryLabel: SRS_APP_CONSTANTS.viewPrintReport.secondaryDisplayName,
					type: SRS_APP_CONSTANTS.viewPrintReport.type,
					status: viewPrintStepStatus,
				};

				// setting all data to the state
				state.steps = steps;
				state.designatedContacts = response.schoolDesignatedContacts;
				state.currentFormData = formData;
				state.initialFormData = formData;
				state.isEveryStepCompleted = isEveryStepCompleted;
				state.status = status;
				if (apiResponse.openViewReport && isEveryStepCompleted) {
					state.activeStepIndex = steps.length - 1;
				} else if (
					apiResponse.tempSchoolData &&
					apiResponse.selectedSchool &&
					apiResponse.tempSchoolData.schCode == apiResponse.selectedSchool.schCode
				) {
					state.activeStepIndex = 0;
				} else if (isEveryStepCompleted) {
					state.activeStepIndex = steps.length - 1;
				} else {
					state.activeStepIndex = 0;
				}
				state.sortedApplicableCohortsList = sortedCohortList;
			}
		},
		updateSchoolInfoFormData: (state, action) => {
			const { completeFormData, steps, activeStep, currentFormData, status } = action.payload;

			// 'steps' from the payload isn't the latest state, so we need to recompute the status for each step..
			const updatedSteps = steps.map((step) => {
				return {
					...step,
					status: step.type === activeStep ? status : step.status,
				};
			});

			const isEveryStepCompleted = updatedSteps
				.filter((step) => step.type !== SRS_APP_CONSTANTS.viewPrintReport.type)
				.every((step) => step.status === STEP_STATUSES.COMPLETED);
			const updatedStatus = updatedSteps.every(
				(step) =>
					step.status === null || step.status === '' || step.status === undefined || step.status === 'Not Started'
			)
				? 'Not Started'
				: isEveryStepCompleted
					? 'Completed'
					: 'In Progress';
			const viewPrintStepStatus = isEveryStepCompleted ? STEP_STATUSES.COMPLETED : STEP_STATUSES.PRISTINE;

			// setting all data to the state
			state.currentFormData = currentFormData;
			state.initialFormData = completeFormData;
			state.isEveryStepCompleted = isEveryStepCompleted;
			state.status = updatedStatus;
			state.steps = updatedSteps.map((step) => {
				return {
					...step,
					status:
						step.type === activeStep
							? status
							: step.type === SRS_APP_CONSTANTS.viewPrintReport.type
								? viewPrintStepStatus
								: step.status,
				};
			});
			return state;
		},
		updateActiveStepIndex: (state, action) => {
			state.activeStepIndex = action.payload;
		},
		setFormStatus: (state, action) => {
			// to track whether form is dirty or not..
			state.isFormDirty = action.payload;
		},
		resetSchoolInfoState: (state) => {
			state = {
				...state,
				currentFormData: null,
				initialFormData: null,
				steps: [],
				isEveryStepCompleted: false,
				activeStepIndex: 0,
				isFormDirty: false,
				sortedApplicableCohortsList: [],
				designatedContacts: [],
				error: null,
			};
			return state;
		},
		updateDesignatedContacts: (state, action) => {
			var currentFormData = state.currentFormData;
			currentFormData.information.designatedContacts = action.payload ?? [];
			state.currentFormData = currentFormData;
			state.designatedContacts = action.payload ?? [];
			state.initialFormData = currentFormData;
			console.log('updateDesignatedContacts', state);
			return state;
		},
		setErrorState: (state, action) => {
			state.error = action.payload;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setSchoolInfoInState,
	updateSchoolInfoFormData,
	updateActiveStepIndex,
	setFormStatus,
	resetSchoolInfoState,
	updateDesignatedContacts,
	setErrorState,
} = slice.actions;

export default slice.reducer;
