export const SRS_ERRORS_CONSTANTS = {
	fieldErrorMessagePreK: 'Error: "Number of Children Entered” does not equal “Total Enrolled.”',
	fieldErrorMessageTKK7th: 'Error: "Number of Students Entered” does not equal “Total Enrolled.” ',
	totalStudentsErolledErrorMessage:
		'Error: "Number of Students Entered" does not equal "Total Enrolled."',
	totalChildrenErolledErrorMessage:
		'Error: "Number of Children Entered" does not equal "Total Enrolled."',

	totalErolledErrorMessage:
		'Error: "Number of Children Entered" does not equal "Total Enrolled."',
	totalEnrolled2ndHalfErrorMessage:
		'Double-check the number of children in each category and total enrollment. Refer to your worksheet.',

	missingChildDosesMessage:
		'"Error: Invalid input in vaccine fields." The appropriate range is between "Total Number of Childrens Reported with Missing Doses" and "Total Number of Childrens Missing Doses" times the number of vaccines.',
	childrenMissingDoseErrorMessage: 'Error: number cannot exceed "Children Missing Doses."',
	missingStudentDosesMessage:
		'"Error: Invalid input in vaccine fields." The appropriate range is between "Total Number of Students Reported with Missing Doses" and "Total Number of Students Missing Doses" times the number of vaccines.',

	studentMissingDoseErrorMessage: 'Error: number cannot exceed "Student Missing Doses."',
	integerMissingMessage: 'Error: Only whole numbers can be entered',
	negativeCountErrorMessage: 'Error: value cannot be negative',
	maxEnrollmentCountValueErrorMessage: 'Error: Enrollment Count cannot be more than 9,999',
	childCare: {
		required: 'Childcare Enrollment is required',
	},
	kindergarten: {
		required: 'Kindergarten Enrollment is required',
	},
	firstGrade: {
		required: 'First Grade Enrollment is required',
	},
	seventhGrade: {
		required: 'Seventh Grade Enrollment is required',
	},
	eighthGrade: {
		required: 'Eighth Grade Enrollment is required',
	},
};

export const HTTP_OPTIONS = {
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Credentials': 'true',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
		'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
	},
};

export const USER_ROLES = {
	systemAdmin: 'SysAdmin',
	schoolUser: 'SchoolUser',
	orgAdmin: 'OrgAdmin',
	helpDesk: 'HelpDesk',
	enrollmentReviewer: 'EnrollmentReviewer',
	enrollmentManager: 'EnrollmentManager',
	localHealthDept: 'LocalHealthDept',
	vaccineInfoManager: 'VaccineInfoManager',
	CDPHProgramAdministrator: 'CDPHProgramAdministrator',
	CDPHFieldStaff: 'CDPHFieldStaff',
	CDPHHealthEAdmin: 'CDPHHealthEAdmin',
	SFSIZReportingAdministrator: 'SFSIZReportingAdministrator',
	LocalHealthDepartment: 'LocalHealthDepartment',
	helpdeskadmin: 'HelpDeskAdmin'
}; 

export const ADMIN_ROLES = [
	USER_ROLES.systemAdmin,
	USER_ROLES.helpDesk,
	USER_ROLES.CDPHProgramAdministrator,
	USER_ROLES.LocalHealthDepartment,
	USER_ROLES.CDPHFieldStaff,
	USER_ROLES.CDPHHealthEAdmin,
	USER_ROLES.SFSIZReportingAdministrator,
	USER_ROLES.helpdeskadmin
];

export const ClosureGroupAdminRoles = ['CDPHProgramAdministrator'];

export const ClosureGroupRoles = [
	{ Key: 'SchoolUser', Value: 'School User' },
	{ Key: 'HelpDesk', Value: 'CAIR HUB Help desk' },
	{ Key: 'LocalHealthDepartment', Value: 'Local Health Department' },
	{ Key: 'CDPHHealthEAdmin', Value: 'Health E-Admin' },
	{ Key: 'CDPHFieldStaff', Value: 'Reporting-CDPH Field Staff​' },
	{ Key: 'HelpDeskAdmin', Value: 'Help Desk Admin​' },
];

export const ClosureGroupUserGroups = [
	'School User',
	'Local Health Department',
	'Health E-Admin',
	'Reporting-CDPH Field Staff​',
	'Help Desk Admin​',
];

export const ClosureGroupCohortList = ['Pre-K/Child Care', 'TK/Kindergarten', '7th Grade'];
