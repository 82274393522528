import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';

type Variant = 'light' | 'primary';

interface LogoProps {
	variant?: Variant;
}

export const AppLogo = styled((props: LogoProps) => {
	const { variant, ...other } = props;

	const theme = useTheme();

	const color = variant === 'light' ? '#C1C4D6' : '#5048E5';
 
	return (
		<svg width="320" height="36" viewBox="0 0 320 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M30.1201 29.7502C28.6401 28.9802 27.4901 27.9102 26.6901 26.5302C25.8901 25.1502 25.4801 23.5502 25.4801 21.7402V13.0302C25.4801 11.2202 25.8801 9.63016 26.6901 8.24016C27.4901 6.86016 28.6401 5.79015 30.1201 5.04015C31.6001 4.29015 33.3201 3.91016 35.2801 3.91016C37.2401 3.91016 38.9201 4.27015 40.3901 4.98015C41.8601 5.70015 43.0001 6.71015 43.8201 8.03015C44.6401 9.35015 45.0501 10.8802 45.0501 12.6102C45.0501 12.8402 44.9001 12.9702 44.6001 13.0202L40.1901 13.2802H40.1201C39.8701 13.2802 39.7401 13.1401 39.7401 12.8701C39.7401 11.5401 39.3301 10.4702 38.5201 9.67015C37.7001 8.87015 36.6301 8.46016 35.3001 8.46016C33.9701 8.46016 32.8601 8.86015 32.0401 9.67015C31.2201 10.4702 30.8101 11.5401 30.8101 12.8701V21.9501C30.8101 23.2601 31.2201 24.3101 32.0401 25.1201C32.8601 25.9201 33.9401 26.3302 35.3001 26.3302C36.6601 26.3302 37.7001 25.9301 38.5201 25.1201C39.3401 24.3201 39.7401 23.2601 39.7401 21.9501C39.7401 21.6701 39.8901 21.5302 40.1901 21.5302L44.6001 21.7202C44.7201 21.7202 44.8301 21.7602 44.9201 21.8302C45.0101 21.9002 45.0501 21.9901 45.0501 22.0901C45.0501 23.8201 44.6401 25.3602 43.8201 26.6902C43.0001 28.0202 41.8601 29.0502 40.3901 29.7802C38.9201 30.5102 37.2201 30.8701 35.2801 30.8701C33.3401 30.8701 31.6001 30.4902 30.1201 29.7202V29.7502Z" fill="#00269B"/>
		<path d="M48.53 30.47C48.44 30.38 48.4 30.28 48.4 30.15V4.66997C48.4 4.53997 48.44 4.43998 48.53 4.34998C48.62 4.25998 48.72 4.21997 48.85 4.21997H58.27C60.15 4.21997 61.81 4.54997 63.23 5.21997C64.65 5.88997 65.75 6.81997 66.53 8.02997C67.31 9.23997 67.7 10.63 67.7 12.21V22.61C67.7 24.19 67.31 25.59 66.53 26.79C65.75 28 64.65 28.93 63.23 29.6C61.81 30.27 60.16 30.6 58.27 30.6H48.85C48.72 30.6 48.62 30.56 48.53 30.47ZM53.9 26.04H58.46C59.62 26.04 60.55 25.65 61.27 24.87C61.99 24.09 62.36 23.05 62.38 21.74V13.07C62.38 11.76 62.02 10.72 61.31 9.93997C60.59 9.15997 59.63 8.76997 58.43 8.76997H53.91C53.78 8.76997 53.72 8.82998 53.72 8.95998V25.84C53.72 25.97 53.78 26.03 53.91 26.03L53.9 26.04Z" fill="#00269B"/>
		<path d="M86.95 5.20018C88.21 5.88018 89.18 6.84019 89.87 8.08018C90.56 9.32018 90.91 10.7502 90.91 12.3602C90.91 13.9702 90.55 15.3402 89.84 16.5402C89.12 17.7502 88.12 18.6802 86.82 19.3502C85.53 20.0202 84.04 20.3502 82.35 20.3502H77C76.87 20.3502 76.81 20.4102 76.81 20.5402V30.1502C76.81 30.2802 76.77 30.3802 76.68 30.4702C76.59 30.5602 76.48 30.6002 76.36 30.6002H71.95C71.82 30.6002 71.72 30.5602 71.63 30.4702C71.54 30.3802 71.5 30.2802 71.5 30.1502V4.64018C71.5 4.51018 71.54 4.41019 71.63 4.32019C71.72 4.23019 71.82 4.19019 71.95 4.19019H82.58C84.24 4.19019 85.69 4.53019 86.95 5.21019V5.20018ZM84.58 15.0902C85.28 14.4202 85.64 13.5502 85.64 12.4702C85.64 11.3902 85.29 10.4702 84.58 9.79018C83.88 9.11018 82.95 8.77019 81.79 8.77019H77C76.87 8.77019 76.81 8.83019 76.81 8.96019V15.8902C76.81 16.0202 76.87 16.0802 77 16.0802H81.79C82.95 16.0802 83.88 15.7502 84.58 15.0802V15.0902Z" fill="#00269B"/>
		<path d="M108.34 4.34998C108.43 4.25998 108.53 4.21997 108.66 4.21997H113.07C113.19 4.21997 113.3 4.25998 113.39 4.34998C113.48 4.43998 113.52 4.53997 113.52 4.66997V30.15C113.52 30.28 113.48 30.38 113.39 30.47C113.3 30.56 113.19 30.6 113.07 30.6H108.66C108.53 30.6 108.43 30.56 108.34 30.47C108.25 30.38 108.21 30.28 108.21 30.15V19.75C108.21 19.62 108.15 19.56 108.02 19.56H99.6901C99.5701 19.56 99.5001 19.62 99.5001 19.75V30.15C99.5001 30.28 99.4601 30.38 99.3701 30.47C99.2801 30.56 99.1701 30.6 99.05 30.6H94.64C94.51 30.6 94.4101 30.56 94.3201 30.47C94.2301 30.38 94.1901 30.28 94.1901 30.15V4.66997C94.1901 4.53997 94.2301 4.43998 94.3201 4.34998C94.4101 4.25998 94.51 4.21997 94.64 4.21997H99.05C99.1701 4.21997 99.2801 4.25998 99.3701 4.34998C99.4601 4.43998 99.5001 4.53997 99.5001 4.66997V14.81C99.5001 14.94 99.5601 15 99.6901 15H108.02C108.15 15 108.21 14.94 108.21 14.81V4.66997C108.21 4.53997 108.25 4.43998 108.34 4.34998Z" fill="#00269B"/>
		<path d="M3.02007 15.2902L3.36007 15.8102C3.42007 15.9102 3.55006 15.9502 3.66006 15.9002L4.57006 15.5402C4.73006 15.4802 4.91006 15.5902 4.91006 15.7702V16.7502C4.91006 16.8902 4.80006 17.0002 4.66006 16.9902C4.52006 16.9802 4.41007 16.8802 4.42007 16.7402V16.5602C4.42007 16.4202 4.31008 16.3102 4.18008 16.3102H4.08007C3.94007 16.3102 3.83007 16.4202 3.83007 16.5502V17.6502C3.83007 17.7201 3.86006 17.7902 3.91006 17.8402L4.78005 18.6002C4.83005 18.6502 4.86007 18.7101 4.86007 18.7901V20.2802C4.86007 20.3502 4.88008 20.4102 4.93008 20.4602L6.06008 21.5502H6.08007L6.90008 22.2402C7.13008 22.4202 7.46008 22.2202 7.40008 21.9402L5.67007 13.8202C5.63007 13.6102 5.39007 13.5102 5.21007 13.6202L3.13006 14.8502C2.98006 14.9402 2.93005 15.1402 3.03005 15.2902H3.02007Z" fill="#C06B12"/>
		<path d="M0.0100098 8.92017V9.28017C0.0100098 9.35017 0.0400171 9.41017 0.0800171 9.46017L1.21002 10.5502C1.21002 10.5502 1.29 10.6602 1.28 10.7302V12.9402C1.28 13.0202 1.31 13.1002 1.38 13.1502L2.30002 13.7502C2.30002 13.7502 2.38002 13.8302 2.40002 13.8802L2.5 14.1802C2.56 14.3702 2.78001 14.4502 2.95001 14.3502L4.89001 13.2102C5.08001 13.1002 5.10001 12.8302 4.92001 12.7002L0 8.94017L0.0100098 8.92017Z" fill="#9D1E96"/>
		<path d="M21.5301 24.1V24.03C21.5301 23.96 21.5001 23.9 21.4601 23.85L9.74011 12.52C9.74011 12.52 9.6601 12.41 9.6701 12.34V11.54C9.6701 11.3 9.41011 11.15 9.21011 11.27L6.65011 12.78C6.46011 12.89 6.44011 13.16 6.62011 13.29L21.0501 24.34C21.2501 24.5 21.5501 24.35 21.5501 24.1H21.5301Z" fill="#007B88"/>
		<path d="M6.27002 14.1101L8.76001 25.7801C8.79001 25.9001 8.88 26.0001 9 26.0201L10 26.2101L12.7 26.7601C12.82 26.7801 12.9 26.8901 12.9 27.0101L12.88 27.6701C12.88 27.8101 12.99 27.9201 13.12 27.9201H14.4C14.49 27.9201 14.57 27.9701 14.61 28.0501L16.18 30.7801C16.23 30.8601 16.32 30.9101 16.42 30.9001L21.34 30.4201C21.45 30.4101 21.54 30.3301 21.56 30.2201L22 27.6401L22.02 27.5701L22.24 27.1401C22.29 27.0401 22.27 26.9201 22.18 26.8401L21.6 26.3301C21.55 26.2801 21.52 26.2201 21.52 26.1401V25.5301C21.52 25.4301 21.48 25.3401 21.4 25.2801L6.56003 13.9101C6.43003 13.8101 6.24 13.9301 6.28 14.0901L6.27002 14.1101Z" fill="#00269B"/>
		<path d="M9.66004 10.2102L9.68006 4.70016C9.68006 4.56016 9.57004 4.45016 9.44004 4.45016L4.59006 4.43018C4.39006 4.43018 4.25004 4.61017 4.29004 4.80017L5.86005 12.1502C5.90005 12.3602 6.14004 12.4602 6.32004 12.3502L9.52005 10.4702C9.61005 10.4102 9.67005 10.3102 9.67005 10.2002L9.66004 10.2102Z" fill="#BC4800"/>
		<path d="M3.41003 4.43018H0.970023C0.800023 4.43018 0.650025 4.57017 0.660025 4.74017L0.730033 7.17017C0.730033 7.17017 0.730034 7.25016 0.700034 7.29016L0.260031 8.12016C0.190031 8.25016 0.230028 8.42016 0.350028 8.51016L4.97002 12.0502C5.10002 12.1502 5.29002 12.0302 5.25002 11.8702L3.71004 4.68018C3.68004 4.54018 3.55003 4.44017 3.41003 4.44017V4.43018Z" fill="#0077CF"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M127.99 35.86V0H128.49V35.86H127.99Z" fill="#6D6E70"/>
		<path d="M168.6 7.70999H165.73C165.73 6.69999 165.14 6.12999 164.04 6.12999C163.13 6.12999 162.54 6.55998 162.54 7.19998C162.54 7.94998 163.15 8.18999 164.81 8.59999C167 9.13999 168.91 9.72999 168.91 12.22C168.91 14.44 167.09 15.86 164.27 15.86C161.45 15.86 159.51 14.44 159.51 11.9H162.45C162.45 13.07 163.07 13.66 164.33 13.66C165.37 13.66 166.01 13.2 166.01 12.46C166.01 11.6 165.36 11.42 163.84 11.02C161.67 10.46 159.71 9.89999 159.71 7.43999C159.71 5.35999 161.43 3.97998 164.16 3.97998C166.89 3.97998 168.61 5.25998 168.61 7.69998L168.6 7.70999Z" fill="#0077CF"/>
		<path d="M177.6 15.61V10.9H173.4V15.61H170.64V4.25H173.4V8.56H177.6V4.25H180.36V15.61H177.6Z" fill="#0077CF"/>
		<path d="M193.12 9.92999C193.12 13.59 191.01 15.87 187.63 15.87C184.25 15.87 182.12 13.59 182.12 9.92999C182.12 6.26999 184.23 3.98999 187.63 3.98999C191.03 3.98999 193.12 6.26999 193.12 9.92999ZM185.01 9.92999C185.01 12.16 185.97 13.47 187.63 13.47C189.29 13.47 190.23 12.16 190.23 9.92999C190.23 7.69999 189.27 6.38998 187.63 6.38998C185.99 6.38998 185.01 7.69999 185.01 9.92999Z" fill="#0077CF"/>
		<path d="M200.05 6.60001V15.62H197.29V6.60001H193.81V4.25H203.53V6.60001H200.05Z" fill="#0077CF"/>
		<path d="M213.16 7.70999H210.29C210.29 6.69999 209.7 6.12999 208.6 6.12999C207.69 6.12999 207.1 6.55998 207.1 7.19998C207.1 7.94998 207.71 8.18999 209.37 8.59999C211.56 9.13999 213.47 9.72999 213.47 12.22C213.47 14.44 211.65 15.86 208.83 15.86C206.01 15.86 204.07 14.44 204.07 11.9H207.01C207.01 13.07 207.63 13.66 208.89 13.66C209.93 13.66 210.57 13.2 210.57 12.46C210.57 11.6 209.92 11.42 208.4 11.02C206.23 10.46 204.27 9.89999 204.27 7.43999C204.27 5.35999 205.99 3.97998 208.72 3.97998C211.45 3.97998 213.17 5.25998 213.17 7.69998L213.16 7.70999Z" fill="#0077CF"/>
		<path d="M220.3 5.19V9.48H225.82V10.42H220.3V15.61H219.26V4.25H226.17V5.19H220.3Z" fill="#0077CF"/>
		<path d="M237.55 9.93005C237.55 13.5201 235.59 15.8201 232.51 15.8201C229.43 15.8201 227.45 13.5201 227.45 9.93005C227.45 6.34005 229.43 4.04004 232.51 4.04004C235.59 4.04004 237.55 6.34005 237.55 9.93005ZM228.54 9.93005C228.54 12.9301 230.07 14.8501 232.5 14.8501C234.93 14.8501 236.46 12.9301 236.46 9.93005C236.46 6.93005 234.91 5.01004 232.5 5.01004C230.09 5.01004 228.54 6.93005 228.54 9.93005Z" fill="#0077CF"/>
		<path d="M243.24 4.25C245.92 4.25 247.14 5.38 247.14 7.28C247.14 8.75 246.26 9.87 244.94 10.14V10.17C245.79 10.43 246.14 10.9 246.63 12.29L247.83 15.61H246.7L245.61 12.45C245.11 11.03 244.56 10.6 243.3 10.6H240.95V15.61H239.91V4.25H243.25H243.24ZM240.94 9.66H243.3C245.23 9.66 246.08 8.64 246.06 7.3C246.06 5.86 245.04 5.19 243.22 5.19H240.94V9.66Z" fill="#0077CF"/>
		<path d="M261.46 7.70999H258.59C258.59 6.69999 258 6.12999 256.9 6.12999C255.99 6.12999 255.4 6.55998 255.4 7.19998C255.4 7.94998 256.01 8.18999 257.67 8.59999C259.86 9.13999 261.77 9.72999 261.77 12.22C261.77 14.44 259.95 15.86 257.13 15.86C254.31 15.86 252.37 14.44 252.37 11.9H255.31C255.31 13.07 255.93 13.66 257.19 13.66C258.23 13.66 258.87 13.2 258.87 12.46C258.87 11.6 258.22 11.42 256.7 11.02C254.53 10.46 252.57 9.89999 252.57 7.43999C252.57 5.35999 254.29 3.97998 257.02 3.97998C259.75 3.97998 261.47 5.25998 261.47 7.69998L261.46 7.70999Z" fill="#0077CF"/>
		<path d="M273.08 8.33998H270.22C270.09 7.10998 269.33 6.38998 268.13 6.38998C266.63 6.38998 265.7 7.72999 265.7 9.92999C265.7 12.13 266.63 13.47 268.1 13.47C269.3 13.47 270.08 12.74 270.24 11.46H273.13C272.91 14.29 270.99 15.87 268.1 15.87C264.83 15.87 262.82 13.62 262.82 9.92999C262.82 6.23999 264.85 3.98999 268.13 3.98999C271.07 3.98999 272.97 5.67999 273.08 8.32999V8.33998Z" fill="#0077CF"/>
		<path d="M281.87 15.61V10.9H277.67V15.61H274.91V4.25H277.67V8.56H281.87V4.25H284.63V15.61H281.87Z" fill="#0077CF"/>
		<path d="M297.39 9.92999C297.39 13.59 295.28 15.87 291.9 15.87C288.52 15.87 286.39 13.59 286.39 9.92999C286.39 6.26999 288.5 3.98999 291.9 3.98999C295.3 3.98999 297.39 6.26999 297.39 9.92999ZM289.28 9.92999C289.28 12.16 290.24 13.47 291.9 13.47C293.56 13.47 294.5 12.16 294.5 9.92999C294.5 7.69999 293.54 6.38998 291.9 6.38998C290.26 6.38998 289.28 7.69999 289.28 9.92999Z" fill="#0077CF"/>
		<path d="M309.48 9.92999C309.48 13.59 307.37 15.87 303.99 15.87C300.61 15.87 298.48 13.59 298.48 9.92999C298.48 6.26999 300.59 3.98999 303.99 3.98999C307.39 3.98999 309.48 6.26999 309.48 9.92999ZM301.37 9.92999C301.37 12.16 302.33 13.47 303.99 13.47C305.65 13.47 306.59 12.16 306.59 9.92999C306.59 7.69999 305.63 6.38998 303.99 6.38998C302.35 6.38998 301.37 7.69999 301.37 9.92999Z" fill="#0077CF"/>
		<path d="M319.14 13.27V15.62H311.24V4.26001H314V13.28H319.14V13.27Z" fill="#0077CF"/>
		<path d="M144.01 2.05005H149.03C149.8 2.05005 150.42 2.67005 150.42 3.44005V6.46005H142.63V3.44005C142.63 2.67005 143.25 2.05005 144.02 2.05005H144.01Z" fill="#DF76B8"/>
		<path d="M143.5 28.9402H149.64C150.06 28.9402 150.31 29.4202 150.07 29.7602L146.99 34.2002C146.79 34.4902 146.36 34.4902 146.16 34.2002L143.08 29.7602C142.84 29.4102 143.08 28.9402 143.51 28.9402H143.5Z" fill="#F3D0AD"/>
		<path d="M145.21 32.8401L147.93 32.8701L146.99 34.2002C146.99 34.2002 146.51 34.7101 146.1 34.1101L145.22 32.8401H145.21Z" fill="#676767"/>
		<path d="M150.64 14.3701H142.5V21.0301H150.64V14.3701Z" fill="#E2742F"/>
		<path d="M143.55 7.22998H149.6C150.18 7.22998 150.65 7.69998 150.65 8.27998V13.6H142.51V8.27998C142.51 7.69998 142.98 7.22998 143.56 7.22998H143.55Z" fill="#E2742F"/>
		<path d="M149.61 28.1702H143.56C142.98 28.1702 142.51 27.7002 142.51 27.1202V21.8002H150.65V27.1202C150.65 27.7002 150.18 28.1702 149.6 28.1702H149.61Z" fill="#E2742F"/>
		<path d="M144.37 24.5201C144.646 24.5201 144.87 24.3007 144.87 24.03C144.87 23.7594 144.646 23.54 144.37 23.54C144.094 23.54 143.87 23.7594 143.87 24.03C143.87 24.3007 144.094 24.5201 144.37 24.5201Z" fill="white"/>
		<path d="M148.93 24.5201C149.206 24.5201 149.43 24.3007 149.43 24.03C149.43 23.7594 149.206 23.54 148.93 23.54C148.654 23.54 148.43 23.7594 148.43 24.03C148.43 24.3007 148.654 24.5201 148.93 24.5201Z" fill="white"/>
		<path d="M146.65 24.5201C146.926 24.5201 147.15 24.3007 147.15 24.03C147.15 23.7594 146.926 23.54 146.65 23.54C146.374 23.54 146.15 23.7594 146.15 24.03C146.15 24.3007 146.374 24.5201 146.65 24.5201Z" fill="white"/>
		<path d="M145.45 26.4202C145.726 26.4202 145.95 26.2008 145.95 25.9302C145.95 25.6596 145.726 25.4402 145.45 25.4402C145.174 25.4402 144.95 25.6596 144.95 25.9302C144.95 26.2008 145.174 26.4202 145.45 26.4202Z" fill="white"/>
		<path d="M147.86 26.4202C148.136 26.4202 148.36 26.2008 148.36 25.9302C148.36 25.6596 148.136 25.4402 147.86 25.4402C147.584 25.4402 147.36 25.6596 147.36 25.9302C147.36 26.2008 147.584 26.4202 147.86 26.4202Z" fill="white"/>
		<path d="M148.85 11.9102C149.126 11.9102 149.35 11.6908 149.35 11.4202C149.35 11.1495 149.126 10.9302 148.85 10.9302C148.574 10.9302 148.35 11.1495 148.35 11.4202C148.35 11.6908 148.574 11.9102 148.85 11.9102Z" fill="white"/>
		<path d="M144.3 11.9102C144.576 11.9102 144.8 11.6908 144.8 11.4202C144.8 11.1495 144.576 10.9302 144.3 10.9302C144.024 10.9302 143.8 11.1495 143.8 11.4202C143.8 11.6908 144.024 11.9102 144.3 11.9102Z" fill="white"/>
		<path d="M146.57 11.9102C146.846 11.9102 147.07 11.6908 147.07 11.4202C147.07 11.1495 146.846 10.9302 146.57 10.9302C146.294 10.9302 146.07 11.1495 146.07 11.4202C146.07 11.6908 146.294 11.9102 146.57 11.9102Z" fill="white"/>
		<path d="M147.78 10C148.056 10 148.28 9.78063 148.28 9.51001C148.28 9.23939 148.056 9.02002 147.78 9.02002C147.504 9.02002 147.28 9.23939 147.28 9.51001C147.28 9.78063 147.504 10 147.78 10Z" fill="white"/>
		<path d="M145.36 10C145.636 10 145.86 9.78063 145.86 9.51001C145.86 9.23939 145.636 9.02002 145.36 9.02002C145.084 9.02002 144.86 9.23939 144.86 9.51001C144.86 9.78063 145.084 10 145.36 10Z" fill="white"/>
		<path d="M160.123 20.6626H161.056V30H160.123V20.6626Z" fill="#231F20"/>
		<path d="M163.049 23.0938H163.932V24.2871C164.24 23.847 164.558 23.5254 164.884 23.3223C165.332 23.0514 165.804 22.916 166.299 22.916C166.633 22.916 166.951 22.9816 167.251 23.1128C167.552 23.244 167.797 23.4196 167.988 23.6396C168.178 23.8555 168.343 24.1665 168.483 24.5728C168.779 24.0311 169.149 23.6206 169.594 23.3413C170.038 23.0578 170.516 22.916 171.028 22.916C171.506 22.916 171.927 23.0366 172.291 23.2778C172.66 23.519 172.932 23.8555 173.11 24.2871C173.288 24.7188 173.377 25.3662 173.377 26.2295V30H172.469V26.2295C172.469 25.4889 172.416 24.9811 172.31 24.7061C172.205 24.4268 172.023 24.2025 171.765 24.0332C171.511 23.8639 171.206 23.7793 170.85 23.7793C170.419 23.7793 170.023 23.9062 169.663 24.1602C169.308 24.4141 169.048 24.7526 168.883 25.1758C168.718 25.599 168.635 26.3057 168.635 27.2959V30H167.753V26.4644C167.753 25.6307 167.7 25.0658 167.594 24.7695C167.493 24.4691 167.313 24.23 167.055 24.0522C166.796 23.8703 166.49 23.7793 166.134 23.7793C165.724 23.7793 165.339 23.9041 164.979 24.1538C164.623 24.3993 164.359 24.7314 164.185 25.1504C164.016 25.5693 163.932 26.2104 163.932 27.0737V30H163.049V23.0938Z" fill="#231F20"/>
		<path d="M175.249 23.0938H176.132V24.2871C176.441 23.847 176.758 23.5254 177.084 23.3223C177.532 23.0514 178.004 22.916 178.499 22.916C178.834 22.916 179.151 22.9816 179.452 23.1128C179.752 23.244 179.997 23.4196 180.188 23.6396C180.378 23.8555 180.543 24.1665 180.683 24.5728C180.979 24.0311 181.349 23.6206 181.794 23.3413C182.238 23.0578 182.716 22.916 183.228 22.916C183.707 22.916 184.128 23.0366 184.492 23.2778C184.86 23.519 185.133 23.8555 185.31 24.2871C185.488 24.7188 185.577 25.3662 185.577 26.2295V30H184.669V26.2295C184.669 25.4889 184.616 24.9811 184.511 24.7061C184.405 24.4268 184.223 24.2025 183.965 24.0332C183.711 23.8639 183.406 23.7793 183.051 23.7793C182.619 23.7793 182.223 23.9062 181.864 24.1602C181.508 24.4141 181.248 24.7526 181.083 25.1758C180.918 25.599 180.835 26.3057 180.835 27.2959V30H179.953V26.4644C179.953 25.6307 179.9 25.0658 179.794 24.7695C179.693 24.4691 179.513 24.23 179.255 24.0522C178.997 23.8703 178.69 23.7793 178.334 23.7793C177.924 23.7793 177.539 23.9041 177.179 24.1538C176.824 24.3993 176.559 24.7314 176.386 25.1504C176.216 25.5693 176.132 26.2104 176.132 27.0737V30H175.249V23.0938Z" fill="#231F20"/>
		<path d="M187.342 23.0938H188.237V26.3184C188.237 27.1055 188.279 27.6471 188.364 27.9434C188.491 28.3665 188.732 28.7008 189.087 28.9463C189.447 29.1917 189.874 29.3145 190.37 29.3145C190.865 29.3145 191.284 29.196 191.626 28.959C191.973 28.7178 192.212 28.4025 192.344 28.0132C192.432 27.7466 192.477 27.1816 192.477 26.3184V23.0938H193.385V26.4834C193.385 27.4355 193.273 28.1528 193.048 28.6353C192.828 29.1177 192.494 29.4964 192.045 29.7715C191.601 30.0423 191.042 30.1777 190.37 30.1777C189.697 30.1777 189.134 30.0423 188.681 29.7715C188.232 29.4964 187.896 29.1156 187.672 28.6289C187.452 28.138 187.342 27.4017 187.342 26.4199V23.0938Z" fill="#231F20"/>
		<path d="M195.365 23.0938H196.254V24.3315C196.609 23.8576 197.003 23.5042 197.434 23.2715C197.866 23.0345 198.336 22.916 198.844 22.916C199.36 22.916 199.817 23.0472 200.215 23.3096C200.617 23.5719 200.913 23.9253 201.103 24.3696C201.294 24.814 201.389 25.5059 201.389 26.4453V30H200.507V26.7056C200.507 25.91 200.473 25.3789 200.405 25.1123C200.299 24.6553 200.1 24.3125 199.808 24.084C199.516 23.8512 199.136 23.7349 198.666 23.7349C198.128 23.7349 197.646 23.9126 197.219 24.2681C196.795 24.6235 196.516 25.0636 196.381 25.5884C196.296 25.9312 196.254 26.5575 196.254 27.4673V30H195.365V23.0938Z" fill="#231F20"/>
		<path d="M203.611 20.25C203.814 20.25 203.987 20.3219 204.131 20.4658C204.275 20.6097 204.347 20.7832 204.347 20.9863C204.347 21.1852 204.275 21.3566 204.131 21.5005C203.987 21.6444 203.814 21.7163 203.611 21.7163C203.412 21.7163 203.24 21.6444 203.097 21.5005C202.953 21.3566 202.881 21.1852 202.881 20.9863C202.881 20.7832 202.953 20.6097 203.097 20.4658C203.24 20.3219 203.412 20.25 203.611 20.25ZM203.166 23.0938H204.061V30H203.166V23.0938Z" fill="#231F20"/>
		<path d="M205.337 23.0938H210.409L206.499 29.2129H210.282V30H204.969L208.879 23.8745H205.337V23.0938Z" fill="#231F20"/>
		<path d="M218.306 23.0938V30H217.43V28.813C217.057 29.2658 216.638 29.6064 216.173 29.835C215.711 30.0635 215.206 30.1777 214.656 30.1777C213.678 30.1777 212.842 29.8244 212.148 29.1177C211.459 28.4067 211.114 27.5435 211.114 26.5278C211.114 25.5334 211.463 24.6828 212.161 23.9761C212.859 23.2694 213.699 22.916 214.681 22.916C215.248 22.916 215.76 23.0366 216.217 23.2778C216.678 23.519 217.083 23.8809 217.43 24.3633V23.0938H218.306ZM214.751 23.7729C214.256 23.7729 213.799 23.8957 213.38 24.1411C212.961 24.3823 212.627 24.723 212.377 25.1631C212.131 25.6032 212.009 26.0687 212.009 26.5596C212.009 27.0462 212.133 27.5117 212.383 27.9561C212.633 28.4004 212.967 28.7474 213.386 28.9971C213.809 29.2425 214.262 29.3652 214.745 29.3652C215.231 29.3652 215.692 29.2425 216.128 28.9971C216.564 28.7516 216.898 28.4194 217.131 28.0005C217.368 27.5815 217.487 27.1097 217.487 26.585C217.487 25.7852 217.222 25.1165 216.693 24.5791C216.169 24.0417 215.521 23.7729 214.751 23.7729Z" fill="#231F20"/>
		<path d="M221.086 20.5293H221.981V23.0938H223.39V23.8555H221.981V30H221.086V23.8555H219.873V23.0938H221.086V20.5293Z" fill="#231F20"/>
		<path d="M225.028 20.25C225.231 20.25 225.404 20.3219 225.548 20.4658C225.692 20.6097 225.764 20.7832 225.764 20.9863C225.764 21.1852 225.692 21.3566 225.548 21.5005C225.404 21.6444 225.231 21.7163 225.028 21.7163C224.829 21.7163 224.657 21.6444 224.514 21.5005C224.37 21.3566 224.298 21.1852 224.298 20.9863C224.298 20.7832 224.37 20.6097 224.514 20.4658C224.657 20.3219 224.829 20.25 225.028 20.25ZM224.583 23.0938H225.478V30H224.583V23.0938Z" fill="#231F20"/>
		<path d="M230.588 22.916C231.65 22.916 232.531 23.3011 233.229 24.0713C233.864 24.7738 234.181 25.6053 234.181 26.5659C234.181 27.5308 233.845 28.375 233.172 29.0986C232.503 29.818 231.642 30.1777 230.588 30.1777C229.53 30.1777 228.665 29.818 227.992 29.0986C227.323 28.375 226.989 27.5308 226.989 26.5659C226.989 25.6095 227.307 24.7801 227.941 24.0776C228.64 23.3032 229.522 22.916 230.588 22.916ZM230.588 23.7856C229.852 23.7856 229.219 24.0586 228.69 24.6045C228.161 25.1504 227.897 25.8105 227.897 26.585C227.897 27.0843 228.017 27.5498 228.259 27.9814C228.5 28.4131 228.826 28.7474 229.236 28.9844C229.647 29.2171 230.097 29.3335 230.588 29.3335C231.079 29.3335 231.53 29.2171 231.94 28.9844C232.351 28.7474 232.677 28.4131 232.918 27.9814C233.159 27.5498 233.28 27.0843 233.28 26.585C233.28 25.8105 233.013 25.1504 232.48 24.6045C231.951 24.0586 231.32 23.7856 230.588 23.7856Z" fill="#231F20"/>
		<path d="M235.838 23.0938H236.726V24.3315C237.082 23.8576 237.475 23.5042 237.907 23.2715C238.339 23.0345 238.808 22.916 239.316 22.916C239.833 22.916 240.29 23.0472 240.687 23.3096C241.089 23.5719 241.386 23.9253 241.576 24.3696C241.766 24.814 241.862 25.5059 241.862 26.4453V30H240.979V26.7056C240.979 25.91 240.946 25.3789 240.878 25.1123C240.772 24.6553 240.573 24.3125 240.281 24.084C239.989 23.8512 239.608 23.7349 239.139 23.7349C238.601 23.7349 238.119 23.9126 237.691 24.2681C237.268 24.6235 236.989 25.0636 236.853 25.5884C236.769 25.9312 236.726 26.5575 236.726 27.4673V30H235.838V23.0938Z" fill="#231F20"/>
		<path d="M247.575 20.6626H249.434C250.471 20.6626 251.174 20.7049 251.542 20.7896C252.096 20.9165 252.547 21.1895 252.894 21.6084C253.241 22.0231 253.414 22.5352 253.414 23.1445C253.414 23.6523 253.294 24.0988 253.053 24.4839C252.816 24.869 252.475 25.161 252.031 25.3599C251.586 25.5545 250.973 25.654 250.19 25.6582L253.548 30H252.392L249.041 25.6582H248.508V30H247.575V20.6626ZM248.508 21.5767V24.7505L250.114 24.7632C250.736 24.7632 251.195 24.7039 251.491 24.5854C251.792 24.467 252.024 24.2786 252.189 24.0205C252.359 23.7581 252.443 23.4661 252.443 23.1445C252.443 22.8314 252.359 22.5479 252.189 22.2939C252.02 22.0358 251.798 21.8517 251.523 21.7417C251.248 21.6317 250.791 21.5767 250.152 21.5767H248.508Z" fill="#231F20"/>
		<path d="M261.089 27.7085L261.838 28.1021C261.592 28.5845 261.309 28.9738 260.987 29.27C260.666 29.5662 260.304 29.7926 259.902 29.9492C259.5 30.1016 259.045 30.1777 258.537 30.1777C257.411 30.1777 256.531 29.8096 255.896 29.0732C255.262 28.3327 254.944 27.4969 254.944 26.5659C254.944 25.6899 255.213 24.9092 255.75 24.2236C256.432 23.3519 257.344 22.916 258.486 22.916C259.663 22.916 260.602 23.3625 261.305 24.2554C261.804 24.8859 262.058 25.673 262.066 26.6167H255.858C255.875 27.4207 256.131 28.0809 256.626 28.5972C257.121 29.1092 257.733 29.3652 258.461 29.3652C258.812 29.3652 259.153 29.3039 259.483 29.1812C259.817 29.0584 260.101 28.8955 260.333 28.6924C260.566 28.4893 260.818 28.1613 261.089 27.7085ZM261.089 25.8486C260.97 25.3747 260.797 24.9959 260.568 24.7124C260.344 24.4289 260.046 24.2004 259.673 24.0269C259.301 23.8534 258.909 23.7666 258.499 23.7666C257.822 23.7666 257.24 23.9845 256.753 24.4204C256.398 24.7378 256.129 25.2139 255.947 25.8486H261.089Z" fill="#231F20"/>
		<path d="M263.742 23.0938H264.624V24.3633C264.976 23.8809 265.38 23.519 265.837 23.2778C266.294 23.0366 266.804 22.916 267.367 22.916C268.348 22.916 269.188 23.2694 269.887 23.9761C270.585 24.6828 270.934 25.5334 270.934 26.5278C270.934 27.5435 270.587 28.4067 269.893 29.1177C269.203 29.8244 268.37 30.1777 267.392 30.1777C266.842 30.1777 266.336 30.0635 265.875 29.835C265.414 29.6064 264.997 29.2658 264.624 28.813V32.5264H263.742V23.0938ZM267.297 23.7729C266.527 23.7729 265.877 24.0417 265.348 24.5791C264.823 25.1165 264.561 25.7852 264.561 26.585C264.561 27.1097 264.677 27.5815 264.91 28.0005C265.147 28.4194 265.483 28.7516 265.919 28.9971C266.359 29.2425 266.823 29.3652 267.309 29.3652C267.788 29.3652 268.236 29.2425 268.655 28.9971C269.078 28.7474 269.415 28.4004 269.664 27.9561C269.914 27.5117 270.039 27.0462 270.039 26.5596C270.039 26.0687 269.914 25.6032 269.664 25.1631C269.419 24.723 269.087 24.3823 268.668 24.1411C268.249 23.8957 267.792 23.7729 267.297 23.7729Z" fill="#231F20"/>
		<path d="M275.872 22.916C276.935 22.916 277.815 23.3011 278.513 24.0713C279.148 24.7738 279.465 25.6053 279.465 26.5659C279.465 27.5308 279.129 28.375 278.456 29.0986C277.787 29.818 276.926 30.1777 275.872 30.1777C274.814 30.1777 273.949 29.818 273.276 29.0986C272.608 28.375 272.273 27.5308 272.273 26.5659C272.273 25.6095 272.591 24.7801 273.225 24.0776C273.924 23.3032 274.806 22.916 275.872 22.916ZM275.872 23.7856C275.136 23.7856 274.503 24.0586 273.974 24.6045C273.446 25.1504 273.181 25.8105 273.181 26.585C273.181 27.0843 273.302 27.5498 273.543 27.9814C273.784 28.4131 274.11 28.7474 274.52 28.9844C274.931 29.2171 275.382 29.3335 275.872 29.3335C276.363 29.3335 276.814 29.2171 277.224 28.9844C277.635 28.7474 277.961 28.4131 278.202 27.9814C278.443 27.5498 278.564 27.0843 278.564 26.585C278.564 25.8105 278.297 25.1504 277.764 24.6045C277.235 24.0586 276.605 23.7856 275.872 23.7856Z" fill="#231F20"/>
		<path d="M280.786 23.0938H281.693V24.103C281.964 23.7052 282.25 23.409 282.55 23.2144C282.851 23.0155 283.164 22.916 283.49 22.916C283.735 22.916 283.997 22.9943 284.277 23.1509L283.813 23.8999C283.627 23.8195 283.471 23.7793 283.344 23.7793C283.047 23.7793 282.762 23.902 282.487 24.1475C282.212 24.3887 282.002 24.7653 281.858 25.2773C281.748 25.6709 281.693 26.4665 281.693 27.6641V30H280.786V23.0938Z" fill="#231F20"/>
		<path d="M285.807 20.5293H286.702V23.0938H288.111V23.8555H286.702V30H285.807V23.8555H284.594V23.0938H285.807V20.5293Z" fill="#231F20"/>
		<path d="M289.748 20.25C289.952 20.25 290.125 20.3219 290.269 20.4658C290.413 20.6097 290.485 20.7832 290.485 20.9863C290.485 21.1852 290.413 21.3566 290.269 21.5005C290.125 21.6444 289.952 21.7163 289.748 21.7163C289.55 21.7163 289.378 21.6444 289.234 21.5005C289.09 21.3566 289.018 21.1852 289.018 20.9863C289.018 20.7832 289.09 20.6097 289.234 20.4658C289.378 20.3219 289.55 20.25 289.748 20.25ZM289.304 23.0938H290.199V30H289.304V23.0938Z" fill="#231F20"/>
		<path d="M292.04 23.0938H292.929V24.3315C293.284 23.8576 293.678 23.5042 294.109 23.2715C294.541 23.0345 295.011 22.916 295.518 22.916C296.035 22.916 296.492 23.0472 296.89 23.3096C297.292 23.5719 297.588 23.9253 297.778 24.3696C297.969 24.814 298.064 25.5059 298.064 26.4453V30H297.182V26.7056C297.182 25.91 297.148 25.3789 297.08 25.1123C296.974 24.6553 296.775 24.3125 296.483 24.084C296.191 23.8512 295.81 23.7349 295.341 23.7349C294.803 23.7349 294.321 23.9126 293.893 24.2681C293.47 24.6235 293.191 25.0636 293.056 25.5884C292.971 25.9312 292.929 26.5575 292.929 27.4673V30H292.04V23.0938Z" fill="#231F20"/>
		<path d="M305.922 23.0938H306.817V28.5972C306.817 29.5662 306.733 30.2751 306.563 30.7236C306.331 31.3584 305.926 31.8472 305.351 32.1899C304.775 32.5327 304.084 32.7041 303.275 32.7041C302.683 32.7041 302.15 32.6195 301.676 32.4502C301.206 32.2852 300.823 32.0609 300.527 31.7773C300.23 31.498 299.958 31.0876 299.708 30.5459H300.673C300.935 31.0029 301.274 31.3415 301.688 31.5615C302.103 31.7816 302.617 31.8916 303.231 31.8916C303.836 31.8916 304.346 31.7773 304.761 31.5488C305.18 31.3245 305.478 31.041 305.656 30.6982C305.833 30.3555 305.922 29.8032 305.922 29.0415V28.686C305.588 29.1092 305.18 29.4351 304.697 29.6636C304.215 29.8879 303.703 30 303.161 30C302.526 30 301.93 29.8434 301.371 29.5303C300.812 29.2129 300.381 28.7876 300.076 28.2544C299.771 27.7212 299.619 27.1351 299.619 26.4961C299.619 25.8571 299.778 25.2625 300.095 24.7124C300.412 24.158 300.85 23.7201 301.409 23.3984C301.972 23.0768 302.564 22.916 303.186 22.916C303.703 22.916 304.183 23.0239 304.627 23.2397C305.076 23.4513 305.508 23.8026 305.922 24.2935V23.0938ZM303.301 23.7729C302.797 23.7729 302.329 23.8957 301.898 24.1411C301.466 24.3823 301.128 24.7188 300.882 25.1504C300.637 25.5778 300.514 26.0412 300.514 26.5405C300.514 27.298 300.768 27.9243 301.276 28.4194C301.784 28.9146 302.442 29.1621 303.25 29.1621C304.067 29.1621 304.723 28.9167 305.218 28.4258C305.717 27.9349 305.967 27.2896 305.967 26.4897C305.967 25.9692 305.852 25.5059 305.624 25.0996C305.395 24.6891 305.072 24.3654 304.653 24.1284C304.234 23.8914 303.783 23.7729 303.301 23.7729Z" fill="#231F20"/>
		</svg>
		
	);
})``;

AppLogo.defaultProps = {
	variant: 'primary',
};

AppLogo.propTypes = {
	variant: PropTypes.oneOf<Variant>(['light', 'primary']),
};
